import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Button,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useUiStore } from "../../hooks/useUiStore";
import Tires from "../../assets/webp/tires.webp";
import DriverRest from "../../assets/webp/driver-rest.webp";
import OldTruck from "../../assets/webp/old-truck.webp";
import Mechanics from "../../assets/webp/mechanics.webp";
import WrongPreventive from "../../assets/webp/wrong-preventive.webp";
import TruckNight from "../../assets/webp/truck-night.webp";
import TruckStop from "../../assets/webp/truck-stop.webp";
import TruckShop from "../../assets/webp/best-truck-shop.webp";
import EquipTruck from "../../assets/webp/equip-truck.webp";
import BestTruckRepairOrlando from "../../assets/webp/best-truck-repair-orlando.webp";
import TruckMaintenance from "../../assets/webp/truck-maintenance.webp";
import TruckWashing from "../../assets/webp/truck-washing.webp";
import TruckWebsite from "../../assets/webp/truck-website.webp";
import TruckDriver from "../../assets/webp/truck-driver.webp";
import FullTruckLoad from "../../assets/webp/full-truck-load.webp";
import Trucks from "../../assets/webp/freight-broker-vs-dispatcher.webp";
import TruckMaintenance2 from "../../assets/webp/truck-maintenance-2.webp";
import Image1 from "../../assets/carousel/1.webp";
import Image2 from "../../assets/carousel/2.webp";
import Image4 from "../../assets/carousel/4.webp";
import Image5 from "../../assets/carousel/5.webp";
import Image6 from "../../assets/carousel/6.webp";
import Image7 from "../../assets/carousel/7.webp";
import Image8 from "../../assets/carousel/8.webp";
import Image9 from "../../assets/carousel/9.webp";
import Image19 from "../../assets/carousel/19.webp";
import Image10 from "../../assets/carousel/10.webp";
import Image11 from "../../assets/carousel/11.webp";
import Image12 from "../../assets/carousel/12.webp";
import Image13 from "../../assets/carousel/13.webp";
import Image14 from "../../assets/carousel/14.webp";
import Image20 from "../../assets/carousel/20.webp";
import Image21 from "../../assets/carousel/21.webp";
import Image22 from "../../assets/carousel/22.webp";
import Image23 from "../../assets/carousel/23.webp";
import Image25 from "../../assets/carousel/25.webp";
import Image33 from "../../assets/carousel/33.webp";
import Image34 from "../../assets/carousel/34.webp";
import Image36 from "../../assets/carousel/36.webp";
import Image37 from "../../assets/carousel/37.webp";
import Image38 from "../../assets/carousel/38.webp";
import Image40 from "../../assets/carousel/40.webp";
import Image41 from "../../assets/carousel/41.webp";
import Image42 from "../../assets/carousel/42.webp";
import Image43 from "../../assets/carousel/43.webp";
import Image44 from "../../assets/carousel/44.webp";
import Image45 from "../../assets/carousel/45.webp";
import TruckDriverCellphone from "../../assets/webp/truck-driver-cellphone.webp";
import RedTriangle from "../../assets/webp/red-triangle.webp";

const ArticleSection = ({ articleInfo }) => {
  const { lang } = useUiStore();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <title>
          {`${
            lang === "EN"
              ? articleInfo.eng_metatitle
              : articleInfo.esp_metatitle
          }`}{" "}
          | CES Mobile Truck & Trailer Repair 🚚
        </title>
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? articleInfo.eng_metadescription
              : articleInfo.esp_metadescription
          }`}
        />
      </Helmet>

      {/* SEO Titles */}
      <Typography variant="h1" sx={{ display: "none" }}>
        {lang === "EN" ? articleInfo.eng_title : articleInfo.esp_title}
      </Typography>

      <Container
        sx={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {/* Títulos */}
        <Grid container sx={{ flexGrow: 1 }} spacing={1}>
          {/* Title */}
          <Grid item xs={12} textAlign="center" alignItems="center">
            {/* Desktop View */}
            <Box
              component="img"
              alt="Desktop banner"
              title="Desktop banner"
              m="auto"
              sx={{
                width: 0.35,
                height: 300,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
              }}
              src={
                articleInfo.position === 0
                  ? Tires
                  : articleInfo.position === 1
                  ? DriverRest
                  : articleInfo.position === 2
                  ? OldTruck
                  : articleInfo.position === 3
                  ? Mechanics
                  : articleInfo.position === 4
                  ? WrongPreventive
                  : articleInfo.position === 5
                  ? TruckNight
                  : articleInfo.position === 6
                  ? TruckStop
                  : articleInfo.position === 7
                  ? TruckShop
                  : articleInfo.position === 8
                  ? EquipTruck
                  : articleInfo.position === 9
                  ? BestTruckRepairOrlando
                  : articleInfo.position === 10
                  ? TruckMaintenance
                  : articleInfo.position === 11
                  ? TruckWashing
                  : articleInfo.position === 12
                  ? TruckWebsite
                  : articleInfo.position === 13
                  ? Tires
                  : articleInfo.position === 14
                  ? OldTruck
                  : articleInfo.position === 15
                  ? Mechanics
                  : articleInfo.position === 16
                  ? WrongPreventive
                  : articleInfo.position === 17
                  ? TruckNight
                  : articleInfo.position === 18
                  ? TruckDriver
                  : articleInfo.position === 19
                  ? Trucks
                  : articleInfo.position === 20
                  ? FullTruckLoad
                  : articleInfo.position === 21
                  ? DriverRest
                  : articleInfo.position === 22
                  ? TruckMaintenance2
                  : articleInfo.position === 23
                  ? Image1
                  : articleInfo.position === 24
                  ? Image2
                  : articleInfo.position === 25
                  ? Tires
                  : articleInfo.position === 26
                  ? FullTruckLoad
                  : articleInfo.position === 27
                  ? TruckWashing
                  : articleInfo.position === 28
                  ? Image4
                  : articleInfo.position === 29
                  ? Image5
                  : articleInfo.position === 30
                  ? Image6
                  : articleInfo.position === 31
                  ? Image7
                  : articleInfo.position === 32
                  ? Image8
                  : articleInfo.position === 33
                  ? Image9
                  : articleInfo.position === 34
                  ? Image10
                  : articleInfo.position === 35
                  ? Image11
                  : articleInfo.position === 36
                  ? Image25
                  : articleInfo.position === 37
                  ? Image22
                  : articleInfo.position === 38
                  ? Image12
                  : articleInfo.position === 39
                  ? Image13
                  : articleInfo.position === 40
                  ? Image14
                  : articleInfo.position === 41
                  ? Image33
                  : articleInfo.position === 42
                  ? Image34
                  : articleInfo.position === 43
                  ? Image19
                  : articleInfo.position === 44
                  ? TruckDriverCellphone
                  : articleInfo.position === 45
                  ? Image23
                  : articleInfo.position === 46
                  ? Image20
                  : articleInfo.position === 47
                  ? Image21
                  : articleInfo.position === 48
                  ? Image36
                  : articleInfo.position === 49
                  ? Image37
                  : articleInfo.position === 50
                  ? Image38
                  : articleInfo.position === 51
                  ? Image40
                  : articleInfo.position === 52
                  ? Image41
                  : articleInfo.position === 53
                  ? Image42
                  : articleInfo.position === 54
                  ? Image43
                  : articleInfo.position === 55
                  ? Image44
                  : articleInfo.position === 56
                  ? Image45
                  : Mechanics
              }
            />
            {/* Mobile View */}
            <Box
              component="img"
              alt="Mobile banner"
              title="Mobile banner"
              sx={{
                width: 1,
                height: 200,
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
              }}
              src={
                articleInfo.position === 0
                  ? Tires
                  : articleInfo.position === 1
                  ? DriverRest
                  : articleInfo.position === 2
                  ? OldTruck
                  : articleInfo.position === 3
                  ? Mechanics
                  : articleInfo.position === 4
                  ? WrongPreventive
                  : articleInfo.position === 5
                  ? TruckNight
                  : articleInfo.position === 6
                  ? TruckStop
                  : articleInfo.position === 7
                  ? TruckShop
                  : articleInfo.position === 8
                  ? EquipTruck
                  : articleInfo.position === 9
                  ? BestTruckRepairOrlando
                  : articleInfo.position === 10
                  ? TruckMaintenance
                  : articleInfo.position === 11
                  ? TruckWashing
                  : articleInfo.position === 12
                  ? TruckWebsite
                  : articleInfo.position === 13
                  ? Tires
                  : articleInfo.position === 14
                  ? OldTruck
                  : articleInfo.position === 15
                  ? Mechanics
                  : articleInfo.position === 16
                  ? WrongPreventive
                  : articleInfo.position === 17
                  ? TruckNight
                  : articleInfo.position === 18
                  ? TruckDriver
                  : articleInfo.position === 19
                  ? Trucks
                  : articleInfo.position === 20
                  ? FullTruckLoad
                  : articleInfo.position === 21
                  ? DriverRest
                  : articleInfo.position === 22
                  ? TruckMaintenance2
                  : articleInfo.position === 23
                  ? Image1
                  : articleInfo.position === 24
                  ? Image2
                  : articleInfo.position === 25
                  ? Tires
                  : articleInfo.position === 26
                  ? FullTruckLoad
                  : articleInfo.position === 27
                  ? TruckWashing
                  : articleInfo.position === 28
                  ? Image4
                  : articleInfo.position === 29
                  ? Image5
                  : articleInfo.position === 30
                  ? Image6
                  : articleInfo.position === 31
                  ? Image7
                  : articleInfo.position === 32
                  ? Image8
                  : articleInfo.position === 33
                  ? Image9
                  : articleInfo.position === 34
                  ? Image10
                  : articleInfo.position === 35
                  ? Image11
                  : articleInfo.position === 36
                  ? Image25
                  : articleInfo.position === 37
                  ? Image22
                  : articleInfo.position === 38
                  ? Image12
                  : articleInfo.position === 39
                  ? Image13
                  : articleInfo.position === 40
                  ? Image14
                  : articleInfo.position === 41
                  ? Image33
                  : articleInfo.position === 42
                  ? Image34
                  : articleInfo.position === 43
                  ? Image19
                  : articleInfo.position === 44
                  ? TruckDriverCellphone
                  : articleInfo.position === 45
                  ? Image23
                  : articleInfo.position === 46
                  ? Image20
                  : articleInfo.position === 47
                  ? Image21
                  : articleInfo.position === 48
                  ? Image36
                  : articleInfo.position === 49
                  ? Image37
                  : articleInfo.position === 50
                  ? Image38
                  : articleInfo.position === 51
                  ? Image40
                  : articleInfo.position === 52
                  ? Image41
                  : articleInfo.position === 53
                  ? Image42
                  : articleInfo.position === 54
                  ? Image43
                  : articleInfo.position === 55
                  ? Image44
                  : articleInfo.position === 56
                  ? Image45
                  : Mechanics
              }
            />
          </Grid>
          <Grid item xs={12} textAlign="center" sx={{ mt: 3 }}>
            <Typography
              variant="p"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                fontSize: 30,
              }}
            >
              {lang === "EN" ? articleInfo.eng_title : articleInfo.esp_title}
            </Typography>
            <Typography
              variant="p"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  fontSize: 40,
                },
              }}
            >
              {lang === "EN" ? articleInfo.eng_title : articleInfo.esp_title}
            </Typography>
            {/* <Divider
              sx={{
                paddingTop: 0.5,
                marginTop: 2,
                marginBottom: 2,
                width: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                background:
                  "linear-gradient(90deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)",
              }}
            /> */}
          </Grid>

          <Grid item xs={12} className="mt-5">
            {/* <Typography variant="body1">{articleInfo.text}</Typography> */}
            <div
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" ? articleInfo.eng_text : articleInfo.esp_text,
              }}
            />
          </Grid>

          <Grid item xs={12} className="mt-5">
            <hr />
            <a
              href="tel:4076745769"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Typography variant="overline">
                {lang === "EN" ? (
                  <b>
                    ⚠️ If you are in Orlando, Tampa, Atlanta, Savannah or
                    Jacksonville, and your truck stopped due to a mechanical or
                    electrical breakdown, we can help you.
                    <b style={{ color: "#D69E48" }}>
                      {" "}
                      Call Cioffi Express Services
                    </b>{" "}
                    at (407) 674-5769 and our technicians will come to the
                    rescue. <br />
                    <br />
                    At Cioffi Express we provide{" "}
                    <b style={{ color: "#D69E48" }}>
                      mobile repair service to trucks and trailers in Orlando,
                      Tampa, Atlanta, Savannah or Jacksonville
                    </b>
                    .
                  </b>
                ) : (
                  <b>
                    ⚠️ Si estás en Orlando, Tampa, Atlanta, Savannah o
                    Jacksonville, y tu camión se detuvo por una avería mecánica
                    o eléctrica, te podemos ayudar.{" "}
                    <b style={{ color: "#D69E48" }}>
                      Llama al servicio de atención al cliente de Cioffi Express
                      Services
                    </b>{" "}
                    al (407) 674-5769 y nuestros mecánicos irán al rescate.{" "}
                    <br />
                    <br />
                    En Cioffi Express brindamos servicio móvil de{" "}
                    <b style={{ color: "#D69E48" }}>
                      reparación a camiones y remolques en Orlando, Tampa,
                      Atlanta, Savannah y Jacksonville
                    </b>
                  </b>
                )}
              </Typography>
            </a>
            <hr />
          </Grid>

          <Grid item xs={12} textAlign="center" className="mt-5">
            <Link
              to={"/blog"}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#FDD383", //FDD383 claro D69E48 oscuro
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid #FDD383",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "rgb(253,211,131,0.7)",
                    border: "1px solid #FDD383",
                  },
                }}
              >
                {lang === "EN" ? (
                  <>{"< "}&nbsp; Back to blog</>
                ) : (
                  <>{"< "}&nbsp; Volver a blog</>
                )}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ArticleSection;
